export const state = {
	users: [],
	verifiedUsers: [],
	unverifiedUsers: [],
};

export const getters = {
	getUsers(state) {
		return state.users;
	},
	getVerifiedUsers(state) {
		return state.verifiedUsers;
	},
	getUnverifiedUsers(state) {
		return state.unverifiedUsers;
	},
};

export const mutations = {
	SET_USERS(state, payload) {
		state.users = payload;
	},
	SET_VERIFIED_USERS(state, payload) {
		state.verifiedUsers = payload;
	},
	SET_UNVERIFIED_USERS(state, payload) {
		state.unverifiedUsers = payload;
	},
};

export const actions = {};
