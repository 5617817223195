export const state = {
	transactions: [],
};

export const getters = {
	getTransactions(state) {
		return state.transactions;
	},
};

export const mutations = {
	SET_TRANSACTIONS(state, payload) {
		state.transactions = payload;
	},
	SET_NEW_TRANSACTIONS(state, { res, ref }) {
		const transactionIndex = state.transactions.findIndex(
			(transaction) => transaction.reference_id === ref
		);
		state.transactions[transactionIndex].status = res.data.data.status;
	},
};

export const actions = {};
